<template>
  <div class="me_container">
    <van-cell-group>
      <van-cell title="我的挂号"
                icon="orders-o"
                is-link
                @click="navRegister" />
      <!-- <van-cell
        title="体检预约"
        icon="todo-list-o"
        is-link
        @click="navExamine"
      /> -->
      <van-cell title="建档信息"
                icon="friends-o"
                is-link
                @click="navQuery" />
      <van-cell title="我的线上问诊"
                icon="manager-o"
                is-link
                @click="navCollection" />
    </van-cell-group>
  </div>
</template>
<script>
import { jsonPost, formPost } from "@/baseAPI";
import qs from "qs";
export default {
  name: "Me",
  data () {
    return {};
  },
  created () { },
  methods: {
    navRegister () {
      this.$router.push({
        path: "/me/register"
      });
    },
    navCollection () {
      this.$router.push({
        path: "/register/reply"
      });
    },
    navExamine () {
      this.$router.push({
        path: "/me/examine"
      });
    },
    navQuery () {
      this.$router.push({
        path: "/me/nrhc"
      });
    }
  }
};
</script>
<style scope>
.me_container .van-cell__title {
  font-size: 40px;
}
.me_container .van-cell {
  padding: 30px;
}
.me_container .van-cell__left-icon,
.me_container .van-cell__right-icon {
  color: #1989fa;
  font-size: 40px;
}
</style>